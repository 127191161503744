// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("stimulus")
require("better-dni")

// stimulus controllers
import "controllers"
// assets from gems not supported by webpacker/yarn yet
// see vendor/gems/index.js and config/webpacker.hml
// from https://www.driftingruby.com/episodes/using-webpacker-in-rails-6
import "gems"

// taken from https://rossta.net/blog/overpacking-a-common-webpacker-mistake.html
// tip: If any file in Webpacker's "packs" directory does not also have a corresponding javascript_pack_tag in your application, then you're overpacking.
import "stylesheets/application.scss"
import "javascript"
import "@fortawesome/fontawesome-free"
require("i18n/frontend.js")

//
import "popper.js"
import "@coreui/coreui"
import "@coreui/icons"
import "@fortawesome/fontawesome-free/js/all"
import "simple-line-icons/scss/simple-line-icons.scss"

require("@client-side-validations/client-side-validations")
import "@client-side-validations/simple-form/dist/simple-form.bootstrap4"

// common application actions
import "actions"

require("jquery-ui/ui/widgets/sortable")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
