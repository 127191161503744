export default function email_logs_index () { return new EmailLogsIndex() }

import datatable_init from "./datatable_init"
import { DEFAULT_OPTIONS } from "./datatable_init"
// import { DEFAULT_COLUMNDEFS } from "./datatable_init"

class EmailLogsIndex {
  // customize datatables for email_logs dataset
  constructor () {
    const columndefs = []
    const options = Object.assign({}, DEFAULT_OPTIONS)
    options.order = [[0, "desc"]]
    new datatable_init("email_logs_table", options, columndefs)
  }
}
