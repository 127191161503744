export default function users_index () { return new UsersIndex() }

import datatable_init from "./datatable_init"
import { DEFAULT_OPTIONS } from "./datatable_init"
// import { DEFAULT_COLUMNDEFS } from "./datatable_init"

class UsersIndex {
  // customize datatables for users dataset
  constructor () {
    const options = Object.assign({}, DEFAULT_OPTIONS)
    options.order = [[1, "asc"]]
    const columndefs = [
      {
        targets: [0, 4, 6, 7, 10, 11],
        className: "text-center"
      },
      {
        targets: [8],
        className: "text-right"
      },
      {
        targets: [0, 10, 11],
        sortable: false
      }
    ]
    new datatable_init("users_table", options, columndefs)
  }
}
