/* globals I18n */
import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["password", "collapse", "agreement", "button"]

  agree () {
    if (this.agreementTarget.checked) {
      this.buttonTarget.removeAttribute("disabled")
    } else {
      this.buttonTarget.setAttribute("disabled", true)
      $(this.collapseTarget).hide()
    }
  }

  newPassword () {
    if (!this.isHidden(this.collapseTarget)) return

    Rails.ajax(
      {
        type: "GET",
        dataType: "json",
        url: this.element.getAttribute("data-url"),
        success: (data, _textStatus, _jqXHR) => this.showPassword(data),
        error: (_jqXHR, textStatus, _errorThrown) => window.common_controller.toast("Error", textStatus || "Lost connection to server", "error")
      }
    )
  }

  showPassword (data) {
    this.passwordTarget.innerText = data.password
    $(this.collapseTarget).show()
    this.copyToClipboard(data.password)
  }

  copyToClipboard (text) {
    const tmp = document.createElement("input")
    document.body.appendChild(tmp)
    tmp.value = text
    tmp.select()
    document.execCommand("copy")
    document.body.removeChild(tmp)
    window.common_controller.toast(
      "",
      I18n.t("frontend.common.password_copied"),
      "notice"
    )
  }

  isHidden (el) {
    const style = window.getComputedStyle(el)
    return (style.display === "none")
  }
}
