/* globals I18n */

import {
  Controller
} from "stimulus"

import {
  notImplementedAlert
} from "javascript/sweetalert.js"

import {
  toast as iziToast
} from "javascript/izitoast.js"

import "bootstrap/js/dist/tooltip"
import swal from "sweetalert2"

export default class extends Controller {
  connect () {
    if (["new", "create"].includes(this.action())) {
      if ($("input.is-invalid").length > 0) {
        $("input.is-invalid").first().focus()
      } else {
        this.takeFocus(".action_new_autofocus")
      }
    } else {
      if (["edit", "update"].includes(this.action())) {
        if ($("input.is-invalid").length > 0) {
          $("input.is-invalid").first().focus()
        } else {
          this.takeFocus(".action_edit_autofocus")
        }
      }
    }
  }

  controller (check) {
    if (check) {
      return ($("body").data("controller") === check)
    } else {
      return $("body").data("controller")
    }
  }

  action (check) {
    if (check) {
      return ($("body").data("action") === check)
    } else {
      return $("body").data("action")
    }
  }

  copyToClipboard (e) {
    const input = document.getElementById(e.target.getAttribute("data-target"))
    input.select()
    document.execCommand("copy")
    swal.fire({
      title: I18n.t("frontend.common.copied"),
      icon: "info"
    })
  }

  takeFocus (selector) {
    if ($(selector).length > 0) {
      $(selector)[0].focus()
      $(selector)[0].select()
    }
  }

  notImplemented (evt) {
    notImplementedAlert(evt)
  }

  toast (title, body, kind) {
    iziToast(title, body, kind)
  }

  syntaxHighlight (json) {
    json = json.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, function (match) {
      var cls = "json-number"
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "json-key"
        } else {
          cls = "json-string"
        }
      } else if (/true|false/.test(match)) {
        cls = "json-boolean"
      } else if (/null/.test(match)) {
        cls = "json-null"
      }
      return "<span class=\"" + cls + "\">" + match + "</span>"
    })
  }
}