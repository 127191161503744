/* eslint-disable camelcase */
import { Controller } from "stimulus"
import email_logs_index from "../actions/email_logs_index"

export default class extends Controller {

  static targets = ["modal"]

  initialize () {
    email_logs_index()
  }

  show_modal () {
    $(this.modal).modal("show")
  }

  close_modal () {
    $(this.modal).modal("hide")
  }

  get modal () {
    return this.targets.find("modal")
  }
}
